import react, { useEffect, useState, useRef } from "react";
import moment from "moment";

import axios from "axios";
import {
  Box,
  Paper,
  Grid,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import MaterialTable from "material-table";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

export default function X({ Parent_u_steamname }) {
  // ------------------------------------------------- DB

  // get user score
  const [userScore, setUserScore] = useState([]);
  const getDB_userScore = async (u_steamname) => {
    await axios
      .get(
        `${REACT_APP_ECLIPSE_API}/get_eclipse_event__user_score_by_steamname?u_steamname=${u_steamname}`
      )
      .then((res) => {
        console.log("userScore", res.data);
        setUserScore(res.data);

        setMyTotalToken(
          res.data.reduce(
            (preValue, curValue) => preValue + parseInt(curValue.u_score),
            0
          )
        );
      });
  };

  // get item stock balance
  const [item_StockBalance, setItem_StockBalance] = useState([]);
  const getDB_item_StockBalance = async () => {
    await axios
      .get(`${REACT_APP_ECLIPSE_API}/get_eclipse_event__item_stock__balance`)
      .then((res) => {
        console.log("item_StockBalance", res.data);
        setItem_StockBalance(res.data);
      });
  };

  // add item stock balance - p1
  const addDB_item_StockBalance_P1 = async (
    item_id,
    item_claimed_by,
    tokenDeduct,
    item_name
  ) => {
    await axios
      .get(`${REACT_APP_ECLIPSE_API}/get_eclipse_event__item_stock__balance`)
      .then((res) => {
        console.log("item_StockBalance", res.data);

        console.log(
          `item ${item_id} bal`,
          res.data.find((x) => x.item_id == item_id).item_balance > 0
        );
        // if bal > 0
        if (res.data.find((x) => x.item_id == item_id).item_balance > 0) {
          //insert
          addDB_item_StockBalance_P2(item_id, tokenDeduct, item_claimed_by);

          addDB_userScore(Parent_u_steamname, tokenDeduct, item_name);

          setTimeout(() => {
            getDB_userScore(Parent_u_steamname);
          }, 2000);
        } else {
          getDB_userScore(Parent_u_steamname);
          getDB_item_StockBalance();
          alert("Claim Failed ");
        }
      });
  };

  // add item stock balance - p2
  const addDB_item_StockBalance_P2 = async (
    item_id,
    tokenDeduct,
    item_claimed_by
  ) => {
    console.log("tokenDeduct P2", tokenDeduct);
    await axios
      .get(
        `${REACT_APP_ECLIPSE_API}/add_eclipse_event__item_stock__balance?item_id=${item_id}&balance=${tokenDeduct}&item_claimed_by=${item_claimed_by}`
      )
      .then((res) => {
        getDB_userScore(Parent_u_steamname);
        getDB_item_StockBalance();
        console.log("p2 done");
        alert("Item Claim Successfully!");
      });
  };

  // add user score
  const addDB_userScore = async (u_steamname, u_score, u_score_desc) => {
    const x = await axios
      .get(
        `${REACT_APP_ECLIPSE_API}/add_eclipse_event__user_score?u_steamname=${u_steamname}&game_num=0&u_score=${u_score}&u_score_desc=${u_score_desc}`
      )
      .then((res) => {});
  };

  //  -------------------------- Table column
  const [state, setState] = useState({
    columns_userScore: [
      {
        title: "gen datetime",
        field: "gen_dt",
        width: "1px",
      },
      {
        title: "Steam Name",
        field: "u_steamname",
        width: "1px",
      },
      {
        title: "Score",
        field: "u_score",
        width: "1px",
        cellStyle: {
          color: "gold",
        },
      },
      {
        title: "Desc",
        field: "u_score_desc",
        width: "1px",
      },
    ],
  });

  // ------------------------------ global variable

  // const [u_score, setU_score] = useState("");
  const [myTotalToken, setMyTotalToken] = useState(-999);

  const [cb_AgreementChecked, setCb_AgreementChecked] = useState(false);

  useEffect(() => {
    console.log("Parent_u_steamname", Parent_u_steamname);
    getDB_userScore(Parent_u_steamname);
    getDB_item_StockBalance();
  }, []);

  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            padding: "20px",
            // alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              color: "white",
              fontSize: "30px",
              marginRight: "5px",
            }}
          >
            {myTotalToken}
          </div>
          <div
            style={{
              backgroundColor: "gold",
              color: "black",
              borderRadius: "30px",
              fontSize: "30px",
            }}
          >
            $
          </div>
          <div
            style={{
              color: "white",
              fontSize: "30px",
              marginLeft: "20px",
            }}
          >
            Token Available
          </div>
        </div>
      </div>

      {/* <Button
        onClick={() => {
          addDB_item_StockBalance_P1("1", "kk2");
        }}
      >
        Test
      </Button> */}
      <br />
      <br />
      <div style={{ display: "flex" }}>
        <div style={{ padding: "10px" }}>
          <Button
            onClick={() => {
              if (cb_AgreementChecked) {
                if (
                  item_StockBalance.find((x) => x.item_id == 1).item_balance > 0
                ) {
                  if (
                    userScore.find((x) => x.u_score_desc == "SADES Spirits") ==
                    undefined
                  ) {
                    if (myTotalToken >= 5) {
                      if (window.confirm("Confirm to Redeem this ?") == true) {
                        addDB_item_StockBalance_P1(
                          "1",
                          Parent_u_steamname,
                          "-5",
                          "SADES Spirits"
                        );
                      }
                    } else {
                      alert(
                        "You dont have enough token. Join more event and earn it "
                      );
                    }
                  } else {
                    alert("only 1 item for each type per user");
                  }
                } else {
                  alert("Item Finished");
                }
              } else {
                alert("Please Ticked the Checkbox");
              }
            }}
            style={{
              backgroundColor: "white",
              color: "#1e8714",
              fontSize: "30px",
              borderRadius: "30px",
              width: "100%",
            }}
          >
            <div>
              <div style={{ display: "flex", padding: "20px" }}>
                <div>-5</div>
                <div
                  style={{
                    backgroundColor: "gold",
                    color: "black",
                    borderRadius: "30px",
                  }}
                >
                  $
                </div>
                <div
                  style={{
                    color: "gray",
                    marginLeft: "20px",
                    fontSize: "16px",
                  }}
                >
                  {`${
                    item_StockBalance.find((x) => x.item_id == 1) != undefined
                      ? item_StockBalance.find((x) => x.item_id == 1)
                          .item_balance
                      : "loading..."
                  } Units Left`}
                </div>
              </div>
              <img
                src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/redeem1.png"
                width="100%"
              ></img>
            </div>
          </Button>
        </div>
        <div style={{ padding: "10px" }}>
          <Button
            onClick={() => {
              if (cb_AgreementChecked) {
                if (
                  item_StockBalance.find((x) => x.item_id == 2).item_balance > 0
                ) {
                  if (
                    userScore.find((x) => x.u_score_desc == "Madtech GMX") ==
                    undefined
                  ) {
                    if (myTotalToken >= 1) {
                      if (window.confirm("Confirm to Redeem this ?") == true) {
                        addDB_item_StockBalance_P1(
                          "2",
                          Parent_u_steamname,
                          "-1",
                          "Madtech GMX"
                        );
                      }
                    } else {
                      alert(
                        "You dont have enough token. Join more event and earn it "
                      );
                    }
                  } else {
                    alert("only 1 item for each type per user");
                  }
                } else {
                  alert("Item Finished");
                }
              } else {
                alert("Please Ticked the Checkbox");
              }
            }}
            style={{
              backgroundColor: "white",
              color: "#1e8714",
              fontSize: "30px",
              borderRadius: "30px",
              width: "100%",
            }}
          >
            <div>
              <div style={{ display: "flex", padding: "20px" }}>
                <div>-1</div>
                <div
                  style={{
                    backgroundColor: "gold",
                    color: "black",
                    borderRadius: "30px",
                  }}
                >
                  $
                </div>
                <div
                  style={{
                    color: "gray",
                    marginLeft: "20px",
                    fontSize: "16px",
                  }}
                >
                  {`${
                    item_StockBalance.find((x) => x.item_id == 2) != undefined
                      ? item_StockBalance.find((x) => x.item_id == 2)
                          .item_balance
                      : "loading..."
                  } Units Left`}
                </div>
              </div>
              <img
                src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/redeem2.png"
                width="100%"
              ></img>
            </div>
          </Button>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "gray",
          padding: "10px",
          borderRadius: "10px",
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              value={cb_AgreementChecked}
              onChange={(e) => setCb_AgreementChecked(e.target.checked)}
              sx={{
                color: "#ffff",
                "&.Mui-checked": {
                  color: "#73ff00",
                },
                "& .MuiSvgIcon-root": { fontSize: 50 },
              }}
            />
          }
          label={
            <div
              style={{
                color: "gold",
                textAlign: "center",
              }}
            >
              I know that redeemed token will be minus from my accumulated
              tokens. It might affect my result for completing the grand prizes.
            </div>
          }
        />
      </div>
      <br />
      <br />
      <br />
      <br />
      <MaterialTable
        style={{
          color: "white",
          background: "rgba(255, 255, 255, .1)",

          boxShadow: "0 8px 32px 0 rgba( 0, 0, 0, 0.4 )",
          backdropFilter: "blur( 11.0px )",
          "&::webkit-backdrop-filter": {
            webkitBackdropFilter: "blur( 18.0px )",
          },
        }}
        title={`Score Board  `}
        columns={state.columns_userScore}
        data={userScore}
        options={{
          headerStyle: {
            background: "#00AEEF",
            border: "none",
            color: "black",
          },

          // exportButton: true,
          // rowStyle: (rowData) => ({
          //   backgroundColor:
          //     rowData.Item_stock_balance < 0
          //       ? "#ff0000"
          //       : rowData.Item_stock_balance === 0
          //       ? "#1a0000"
          //       : "",
          //   color:
          //     rowData.Item_stock_balance < 10 ? "#ffcc00" : "#ffffff",
          //   // selectedRow === rowData.tableData.id ? "#006644" : "#262626",
          //   fontSize: 12,
          // }),
          // filtering: true,
          // grouping: true,
          // exportAllData: true,
          pageSize: 5,
          pageSizeOptions: [5, 10, 20],
          paginationType: "stepped",
          showFirstLastPageButtons: false,
          search: false,
          // showTitle: false,
          // header: false,
          // actionsColumnIndex: -1
          // fixedColumns: {
          //     left: 1,
          // }
        }}
        onRowClick={(evt, selectedRow) => {
          // alert(selectedRow.id);
        }}
        actions={
          [
            // {
            //   icon: "save",
            //   tooltip: "Save User",
            //   onClick: (event, rowData) => {
            //     handleClickOpen_1_1();
            //     // getDB_patroninfo_byid(rowData.id);
            //     // alert(rowData.id);
            //   },
            //   onClick2: (event, rowData) => {
            //     handleClickOpen_1_2();
            //     // getDB_patroninfo_byid(rowData.id);
            //     // alert(rowData.id);
            //   },
            // },
          ]
        }
        components={
          {
            // Action: (props) => (
            //   <>
            //     <Button
            //       // classes={{ root: classes.button }}
            //       onClick={(event) => props.action.onClick(event, props.data)}
            //     >
            //       EDIT
            //     </Button>
            //     <Button
            //       // classes={{ root: classes.button }}
            //       onClick={(event) => props.action.onClick2(event, props.data)}
            //     >
            //       <div style={{ color: "#2bc3ff" }}>MANAGE</div>
            //     </Button>
            //   </>
            // ),
          }
        }
      />
    </>
  );
}
