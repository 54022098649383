import react, { useState, useEffect } from "react";
import io from "socket.io-client";
import axios from "axios";
import ScrollToBottom from "react-scroll-to-bottom";
import MaterialTable from "material-table";

// import { styled } from '@mui/material/styles';
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import Page_ScoreBoard from "./Page_ScoreBoard";
import Page_ChatRoom_Lobby from "./Page_ChatRoom_Lobby";
import Page_TimerCountDown from "./Page_TimerCountDown";
import Page_Event from "./Page_Event";
import Page_Redeem from "./Page_Redeem";
import Page_RulesAndRegulation from "./Page_RulesAndRegulation";
import Page_Register from "./Page_Register";
import Page_Event_Now from "./Page_Event_Now";
import Page_Event_Next from "./Page_Event_Next";
import Page_LobbyCode from "./Page_LobbyCode";

import COM_Carousel from "../Component/COM_Carousel";
import COM_Carousel2 from "../Component/COM_Carousel2";

import { TabContext, TabList, TabPanel } from "@mui/lab";

// -------------------------------------------------  firebase - 1/2
import { authentication } from "../firebase-config";
import {
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

// const socket = io.connect("http://localhost:8001");
const socket = io.connect("https://eclipserdp.dvrdns.org");

export default function X() {
  // ------------------------------------------------- DB

  // get user info by uid
  const [firebaseAPI_DB_users, setFirebaseAPI_DB_users] = useState([]);
  const getFirebaseAPI_DB_users_List = async (user_login_uid) => {
    const x = await axios
      .get(
        `${REACT_APP_ECLIPSE_API}/get_eclipse_event__event_user__byuid?user_login_uid=${user_login_uid}`
      )
      .then((res) => {
        setFirebaseAPI_DB_users(res.data[0]);
        // console.log("getFirebaseAPI_DB_users_List", res.data[0]);
        if (res.data[0] != undefined) {
          // that is registered user
          setRegisteredUserStatus(true);
          // get the user data
          setDb_u_id(res.data[0].id);
          setDeliveryAdd(res.data[0].user_deliveryadd);
          setPostCode(res.data[0].user_postcode);
          setCity(res.data[0].user_city);
          setContactNo(res.data[0].user_contactno);
          setSteamName(res.data[0].user_steamname);
          setCb_User_event1_checkpoint(
            res.data[0]?.user_event1_checkpoint == "true" ? true : false
          );
          // console.log("db chkp1 = ", res.data[0]?.user_event1_checkpoint);
        } else {
          setRegisteredUserStatus(false);
        }
      });
  };

  // get user info by uid
  const [eventSetting, setEventSetting] = useState([]);
  const getDB_eventSetting = async (user_login_uid) => {
    const x = await axios
      .get(`${REACT_APP_ECLIPSE_API}/get_eclipse_event__event_setting`)
      .then((res) => {
        setEventSetting(res.data[0]);
        // console.log("eventSetting", res.data[0]);
        setYt_LiveLink(res.data[0].yt_live_url);
      });
  };

  // get user score
  const [userScore, setUserScore] = useState([]);
  const getDB_userScore = async (user_login_uid) => {
    const x = await axios
      .get(`${REACT_APP_ECLIPSE_API}/get_eclipse_event__user_score`)
      .then((res) => {
        // console.log("userScore", res.data[1]);
        setUserScore(res.data[1]);
      });
  };
  //  -------------------------- Table column

  const [state, setState] = useState({
    columns_userScore: [
      // {
      //   title: "No",
      //   field: "num",
      //   width: "1px",
      // },
      {
        title: "Steam Name",
        field: "user_steamname",
        width: "1px",
        render: (rowData) => (
          <>
            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px" }}>#{rowData.num}</div>
              <img
                src={rowData.user_profile_pic_url}
                style={{ borderRadius: "30px", height: "30px" }}
              />

              <div> {rowData.user_steamname}</div>
            </div>
          </>
        ),
      },
      {
        title: "Score",
        field: "total_score",
        width: "1px",
      },
    ],
  });

  // -------------------------------------------------  firebase - 2/2
  const [firebase_userInfo, setFirebase_userInfo] = useState({});

  onAuthStateChanged(authentication, (currentUser) => {
    setFirebase_userInfo(currentUser);
  });

  const singInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(authentication, provider)
      .then((re) => {
        setFirebase_userInfo(re);
        // console.log("re", re);
      })
      .catch((err) => {
        // console.log("re-err", err);
      });
  };

  const signInWithFacebook = () => {
    const provider = new FacebookAuthProvider();
    signInWithPopup(authentication, provider)
      .then((re) => {
        setFirebase_userInfo(re);
        // console.log("re", re);
      })
      .catch((err) => {
        // console.log("re-err", err);
      });
  };

  // -------------------------------------- Global Variable

  const [deliveryAdd, setDeliveryAdd] = useState();
  const [postCode, setPostCode] = useState();
  const [city, setCity] = useState();
  const [contactNo, setContactNo] = useState();

  const [db_u_id, setDb_u_id] = useState();
  const [steamName, setSteamName] = useState(
    "dummyAcc-" + Math.random() * 100000000000000000
  );

  const [yt_LiveLink, setYt_LiveLink] = useState("");

  const [cb_User_event1_checkpoint, setCb_User_event1_checkpoint] =
    useState(false);
  const hC_cb_User_event1_checkpoint = (event) => {
    setCb_User_event1_checkpoint(event.target.checked);
    setSteamName("");
  };

  const [registeredUserStatus, setRegisteredUserStatus] = useState(false);

  //---------------------------------- tabs
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    socket.on("res_change_yt_rul", (data) => {
      // console.log("#res_change_yt_rul", data);
      setYt_LiveLink(data);
    });
  }, [socket]);

  useEffect(() => {
    getDB_eventSetting();
    getDB_userScore();
    document.documentElement.scrollTop = 0;
  }, []);

  // -------------------------------------------------  firebase - 3/3

  useEffect(() => {
    // console.log("#useEffect 1", firebase_userInfo?.displayName);
    // console.log("#useEffect2. ", firebase_userInfo);
    if (firebase_userInfo?.uid) {
      // console.log("a1", firebase_userInfo?.uid);
      // console.log("a1", firebase_userInfo?.email);
      getFirebaseAPI_DB_users_List(firebase_userInfo?.uid);

      // nevigate to main page
      // window.location.href = "#/main";
    }
  }, [firebase_userInfo]);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={9}>
          <div style={{ backgroundColor: "" }}>
            {/* A1 */}
            {/* <Grid item xs={12}>
              <Page_TimerCountDown set_until="05/12/2021 19:00:00" />
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center", color: "gold" }}>
              <div>Event End at 5th December 2021, 8PM (GMT +8)</div>
              <div>Hurry Register Now & Win More Prizes</div>
            </Grid> */}
            <Grid item xs={12}>
              <div style={{ backgroundColor: "" }}>
                {/* B1 : Nav */}
                <Box sx={{ width: "100%", typography: "body1" }}>
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                        style={{ backgroundColor: "#00AEEF" }}
                      >
                        s
                        <Tab
                          label={
                            <>
                              <img
                                src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_Sades2.png"
                                height="30px"
                                style={{ marginLeft: "10px" }}
                              />
                            </>
                          }
                          value="1"
                        />
                        <Tab label="Watch" value="1" />
                        <Tab label="Event" value="2" />
                        <Tab
                          label={
                            registeredUserStatus ? (
                              "EDIT"
                            ) : (
                              <div
                                style={{
                                  backgroundColor: "black",
                                  color: "#73ff00",
                                  padding: "20px",
                                }}
                              >
                                REGISTER NOW!
                              </div>
                            )
                          }
                          value="3"
                        />
                        <Tab label="Redeem" value="4" />
                        <Tab label="RULES" value="5" />
                        <Tab
                          style={{
                            position: "absolute",
                            right: "0",
                          }}
                          label={
                            <>
                              <div
                                style={{
                                  marginLeft: "10px",
                                  backgroundColor: "black",
                                  color: "#73ff00",
                                  padding: "20px",
                                  marginTop: "-7px",
                                }}
                              >
                                <img
                                  src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_Serverdna2.png"
                                  height="30px"
                                />
                              </div>
                            </>
                          }
                          value="6"
                          // onClick={() => {
                          //   signOut(authentication);
                          //   setRegisteredUserStatus(false);
                          //   // setTimeout(() => {
                          //   //   window.location.href = "/";
                          //   // }, 1000);
                          // }}
                        />
                      </TabList>
                    </Box>
                    <TabPanel
                      value="1"
                      style={{
                        padding: 0,
                      }}
                    >
                      <Grid item xs={12}>
                        <div style={{ backgroundColor: "" }}>
                          B2 : Welcome
                          <Grid container spacing={1}>
                            <Grid item xs={3}>
                              <div style={{ backgroundColor: "" }}>
                                <Page_Event_Now db_u_id={db_u_id} />
                              </div>
                            </Grid>
                            <Grid item xs={9}>
                              <div
                                class="video-container"
                                style={{ backgroundColor: "" }}
                              >
                                B4 : Live video
                                <Grid container spacing={1}>
                                  <Grid item xs={12}>
                                    <div
                                      class="video-container"
                                      style={{ backgroundColor: "" }}
                                    >
                                      B4 : Live video
                                      <iframe
                                        // width={560 * 0.4} //560 56
                                        // height={315 * 0.4} //315  31

                                        src={`${yt_LiveLink}`}
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen=""
                                      ></iframe>
                                    </div>
                                  </Grid>
                                </Grid>
                                <Grid container>
                                  <Grid container xs={12} spacing={1}>
                                    <Grid item xs={4}>
                                      <div style={{ backgroundColor: "" }}>
                                        L1
                                        <div
                                          class="video-container"
                                          style={{ backgroundColor: "lime" }}
                                        >
                                          C2 : YT logo_Eclipse
                                          <iframe
                                            // width={560 * 0.4} //560 56
                                            // height={315 * 0.4} //315  31

                                            src="https://www.youtube.com/embed/videoseries?list=PLSOdobpV2V0YNtckWKzL97NOhg72WO0I9&autoplay=1&mute=1"
                                            title="YouTube video player"
                                            frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen=""
                                          ></iframe>
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                      <div
                                        style={{
                                          backgroundColor: "",
                                          // height: "300px",
                                        }}
                                      >
                                        <br />

                                        <Page_LobbyCode />
                                      </div>
                                      <br />
                                      {/* <COM_Carousel /> */}
                                    </Grid>
                                    <Grid item xs={4}>
                                      <br />
                                      <div style={{ backgroundColor: "" }}>
                                        <Page_Event_Next />
                                      </div>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        C1
                        <Grid container spacing={1}>
                          <Grid item xs={3}>
                            <div style={{ backgroundColor: "" }}>Empty1</div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </TabPanel>
                    <TabPanel value="2">
                      <Page_Event />
                    </TabPanel>
                    <TabPanel value="3">
                      <Page_Register />
                    </TabPanel>
                    <TabPanel value="4">
                      <Page_Redeem Parent_u_steamname={steamName} />
                    </TabPanel>
                    <TabPanel value="5">
                      <Page_RulesAndRegulation />
                    </TabPanel>
                    <TabPanel value="6"></TabPanel>
                  </TabContext>
                </Box>
              </div>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div style={{ marginTop: "0px" }}></div>
          <div style={{ backgroundColor: "" }}>
            {/* D */}
            {firebase_userInfo && registeredUserStatus ? (
              <>
                <div
                  style={{
                    backgroundColor: "#4f4f4f",
                    padding: "5px 0px 0px 5px",
                    borderRadius: "10px",
                    display: "flex",
                    fontSize: "10px",
                    color: "#fff",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <img
                      src={
                        firebase_userInfo?.providerData?.find(
                          (x) => x.providerId == "facebook.com"
                        ) != undefined
                          ? firebase_userInfo?.providerData?.find(
                              (x) => x.providerId == "facebook.com"
                            ).photoURL
                          : `https://avatars.dicebear.com/api/pixel-art/${firebase_userInfo?.uid}.svg`
                      }
                      style={{
                        borderRadius: "50%",
                        height: "50px",
                      }}
                    />
                    <Button
                      style={{
                        color: "#ff0000",
                        // width: "20%",
                        textTransform: "none",
                        fontSize: "12px",
                      }}
                      onClick={() => {
                        signOut(authentication);
                        setRegisteredUserStatus(false);
                      }}
                    >
                      LOGOUT
                    </Button>
                  </div>
                  <div style={{ padding: "0px 0px 0px 10px" }}>
                    <div>
                      {firebase_userInfo?.providerData?.find(
                        (x) => x.providerId == "facebook.com"
                      ) != undefined
                        ? firebase_userInfo?.providerData?.find(
                            (x) => x.providerId == "facebook.com"
                          ).displayName
                        : "No Name"}
                    </div>
                    <div>{firebase_userInfo?.email}</div>

                    <div style={{ color: "gray" }}>
                      {firebase_userInfo?.uid}
                    </div>
                    <div style={{ color: "gold" }}>
                      {registeredUserStatus
                        ? "Registered User"
                        : "Not yet Register"}
                    </div>
                    <div style={{ color: "gold" }}>{steamName}</div>
                  </div>
                </div>
              </>
            ) : (
              <div></div>
            )}
          </div>
          <Grid item xs={12}>
            <div style={{ backgroundColor: "" }}>
              D1 : Scoreboard
              <Page_ScoreBoard parent_game_num="1" />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div
              style={
                {
                  //  backgroundColor: "lime"
                }
              }
            >
              D2 : Chat
              {!registeredUserStatus ? (
                <Page_Register />
              ) : (
                <div>
                  <Page_ChatRoom_Lobby />
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </Grid>
      <br />
      <br />
      <br />
      <div style={{ fontSize: "40px", color: "gold", textAlign: "center" }}>
        Like & Share
      </div>
      <br />
      <Grid
        container
        spacing={3}
        style={{ color: "gold", padding: "10px 10px 10px 10px " }}
      >
        <Grid container xs={4}>
          <Grid item xs={4}>
            <img
              src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_Sades.png"
              height="30px"
            />
          </Grid>
          <Grid item xs={4}>
            <div style={{ padding: "10px 0px 0px 10px" }}>
              <div
                class="fb-like"
                data-href="https://www.facebook.com/sades.my"
                data-width="100"
                data-layout="standard"
                data-action="like"
                data-size="large"
                data-colorscheme="dark"
              ></div>
            </div>
          </Grid>
        </Grid>
        <Grid container xs={4}>
          <Grid item xs={4}>
            <img
              src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_Serverdna.png"
              height="30px"
            />
          </Grid>
          <Grid item xs={4}>
            <div style={{ padding: "10px 0px 0px 10px" }}>
              <div
                class="fb-like"
                data-href="https://www.facebook.com/serverdna"
                data-width="100"
                data-layout="standard"
                data-action="like"
                data-size="large"
                data-colorscheme="dark"
              ></div>
            </div>
          </Grid>
        </Grid>
        <Grid container xs={4}>
          <Grid item xs={4}>
            <img
              src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_Maxsun.png"
              height="30px"
            />
          </Grid>
          <Grid item xs={4}>
            <div style={{ padding: "10px 0px 0px 10px" }}>
              <div
                class="fb-like"
                data-href="https://www.facebook.com/MaxsunMY"
                data-width="100"
                data-layout="standard"
                data-action="like"
                data-size="large"
                data-colorscheme="dark"
              ></div>
            </div>
          </Grid>
        </Grid>
        <Grid container xs={4}>
          <Grid item xs={4}>
            <img
              src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_TitanArmy.png"
              height="30px"
            />
          </Grid>
          <Grid item xs={4}>
            <div style={{ padding: "10px 0px 0px 10px" }}>
              <div
                class="fb-like"
                data-href="https://www.facebook.com/TitanArmyMy"
                data-width="100"
                data-layout="standard"
                data-action="like"
                data-size="large"
                data-colorscheme="dark"
              ></div>
            </div>
          </Grid>
        </Grid>
        <Grid container xs={4}>
          <Grid item xs={4}>
            <img
              src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_Madtech.png"
              height="30px"
            />
          </Grid>
          <Grid item xs={4}>
            <div style={{ padding: "10px 0px 0px 10px" }}>
              <div
                class="fb-like"
                data-href="https://www.facebook.com/madtech.my"
                data-width="100"
                data-layout="standard"
                data-action="like"
                data-size="large"
                data-colorscheme="dark"
              ></div>
            </div>
          </Grid>
        </Grid>
        <Grid container xs={4}>
          <Grid item xs={4}>
            <img
              src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_InWin.png"
              height="30px"
            />
          </Grid>
          <Grid item xs={4}>
            <div style={{ padding: "10px 0px 0px 10px" }}>
              <div
                class="fb-like"
                data-href="https://www.facebook.com/inwin.my"
                data-width="100"
                data-layout="standard"
                data-action="like"
                data-size="large"
                data-colorscheme="dark"
              ></div>
            </div>
          </Grid>
        </Grid>
        <Grid container xs={4}>
          <Grid item xs={4}>
            <img
              src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_Eclipse.png"
              height="30px"
            />
          </Grid>
          <Grid item xs={4}>
            <div style={{ padding: "10px 0px 0px 10px" }}>
              <div
                class="fb-like"
                data-href="https://www.facebook.com/EclipseGroupMY"
                data-width="100"
                data-layout="standard"
                data-action="like"
                data-size="large"
                data-colorscheme="dark"
              ></div>
            </div>
          </Grid>
        </Grid>
        <Grid container xs={4}>
          <Grid item xs={4}>
            <img
              src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_Alitrade.png"
              height="30px"
            />
          </Grid>
          <Grid item xs={4}>
            <div style={{ padding: "10px 0px 0px 10px" }}>
              <div
                class="fb-like"
                data-href="https://www.facebook.com/AlitradeMY"
                data-width="100"
                data-layout="standard"
                data-action="like"
                data-size="large"
                data-colorscheme="dark"
              ></div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
