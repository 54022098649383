import React, {
  Component,
  useState,
  useReducer,
  useEffect,
  useRef,
} from "react";
import ReactDOM from "react-dom";
import "./ChatRoom.css";
import "./Youtube.css";
import "./ScrollBoard.css";

// ------------------------------------------------- router 1/3
import { HashRouter, BrowserRouter, Route, Switch } from "react-router-dom";

import axios from "axios";

import { Box, Paper, Grid, Button, TextField, Typography } from "@mui/material";

import Page_ScoreBoard from "./Page/Page_ScoreBoard";
import Page_PreRegister from "./Page/Page_PreRegister";
import Page_EventMain from "./Page/Page_EventMain";
import COM_LifeVideo from "./Component/COM_LifeVideo";
import Page_Register from "./Page/Page_Register";
import Page_ChatRoom_Lobby from "./Page/Page_ChatRoom_Lobby";
import Page_Error from "./Page/Page_Error";
import Page_AdminPanel from "./Page/Page_AdminPanel";
import Page_TimerCountDown from "./Page/Page_TimerCountDown";
import Page_Summary from "./Page/Page_Summary";

import Page_Login from "./Page/Page_Login";

import COM_UserProfile from "./Component/COM_UserProfile";
// ------------------------------------------------- Mui Theme 1/2
import { ThemeProvider } from "@mui/styles";
import { createGenerateClassName } from "@mui/styles";

import HttpsRedirect from "react-https-redirect";

// -------------------------------------------------  firebase - 1/2
import { authentication } from "./firebase-config";
import {
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

// ------------------------------------------------- Mui Theme 2/2
const theme = createGenerateClassName({
  palette: {
    type: "dark",
  },
});

function App() {
  // ------------------------------------------------- DB

  // get user by email
  const getVersion = async () => {
    const x = await axios
      .get(`${REACT_APP_ECLIPSE_API}/get_eclipse_event__config`)
      .then((res) => {
        if (localStorage.getItem("ver") === null) {
          localStorage.setItem("ver", res.data[0].value);

          window.location.reload();
        }
        if (localStorage.getItem("ver") !== res.data[0].value) {
          localStorage.setItem("ver", res.data[0].value);
          // alert("New Update Available ! " + res.data[0].value);

          window.location.reload();
        }
      });
  };

  // -------------------------------------------------  firebase - 2/2
  const [firebase_userInfo, setFirebase_userInfo] = useState({});

  onAuthStateChanged(authentication, (currentUser) => {
    setFirebase_userInfo(currentUser);
  });

  const singInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(authentication, provider)
      .then((re) => {
        setFirebase_userInfo(re);
        console.log("re", re);
      })
      .catch((err) => {
        console.log("re-err", err);
      });
  };

  const signInWithFacebook = () => {
    const provider = new FacebookAuthProvider();
    signInWithPopup(authentication, provider)
      .then((re) => {
        setFirebase_userInfo(re);
        console.log("re", re);
      })
      .catch((err) => {
        console.log("re-err", err);
      });
  };

  useEffect(() => {
    getVersion();
  }, []);

  return (
    <>
      <HttpsRedirect>
        <ThemeProvider theme={theme}>
          {/* <BrowserRouter> */}
          <HashRouter hashType="slash">
            <Switch>
              <Route exact path="/" component={Page_Summary} />
              {/* <Route exact path="/register" component={Page_PreRegister} />
              <Route exact path="/main" component={Page_EventMain} />
              <Route
                exact
                path="/Page_AdminPanel_Stcf8yhxm27RvffpYm9mnyn9FdwAMkhXFtzqNcdJdBbzCEXJVcj9atxexHHPSXkL"
                component={Page_AdminPanel}
              />
              <Route
                exact
                path="/Page_ChatRoom_Lobby"
                component={Page_ChatRoom_Lobby}
              />
              <Route
                exact
                path="/Page_TimerCountDown"
                component={Page_TimerCountDown}
              /> */}
              <Route exact path="*" component={Page_Error} />
            </Switch>
          </HashRouter>
          {/* </BrowserRouter> */}
        </ThemeProvider>
      </HttpsRedirect>
      {/* {localStorage.getItem("ver")} */}
    </>
  );
}

export default App;
