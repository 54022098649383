import react, { useEffect, useState } from "react";
import { Box, Paper, Grid, Button, TextField } from "@mui/material";
import "../Css/Page_Summary.css";

export default function X() {
  return (
    <div class="background">
      <div>
        <div class="grid-container">
          <div class="grid-item grid-item-title">
            <img
              src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_Serverdna2.png"
              width="100%"
            />
          </div>
          <div class="announcement">
            <div
              style={{
                border: "none",
                overflow: "hidden",
                backgroundColor: "white",
              }}
              class="fb-post"
              data-href="https://www.facebook.com/serverdna/posts/968664070385315"
              data-width="500"
            ></div>
          </div>
          <div class="announcement">
            <div class="announcement">Congratulation for all winners</div>
            <div class="announcement">See you again soon!</div>
          </div>
        </div>
        <div class="grid-container-FBLIKE">
          <div class="grid-item-item-FBLIKE">
            <img
              src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_Sades.png"
              height="30px"
            />
          </div>
          <div class="grid-item-item-FBLIKE">
            <div
              style={{
                padding: "10px 0px 0px 10px",
              }}
            >
              <div
                class="fb-like"
                data-href="https://www.facebook.com/sades.my"
                data-width="500"
                data-layout="standard"
                data-action="like"
                data-size="large"
                data-colorscheme="dark"
              ></div>
            </div>
          </div>
          <div class="grid-item-item-FBLIKE">
            <img
              src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_Serverdna.png"
              height="30px"
            />
          </div>
          <div class="grid-item-item-FBLIKE">
            <div style={{ padding: "10px 0px 0px 10px" }}>
              <div
                class="fb-like"
                data-href="https://www.facebook.com/serverdna"
                data-width="500"
                data-layout="standard"
                data-action="like"
                data-size="large"
                data-colorscheme="dark"
              ></div>
            </div>
          </div>
          <div class="grid-item-item-FBLIKE">
            {" "}
            <img
              src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_Maxsun.png"
              height="30px"
            />
          </div>
          <div class="grid-item-item-FBLIKE">
            <div style={{ padding: "10px 0px 0px 10px" }}>
              <div
                class="fb-like"
                data-href="https://www.facebook.com/MaxsunMY"
                data-width="500"
                data-layout="standard"
                data-action="like"
                data-size="large"
                data-colorscheme="dark"
              ></div>
            </div>
          </div>
          <div class="grid-item-item-FBLIKE">
            {" "}
            <img
              src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_TitanArmy.png"
              height="30px"
            />
          </div>
          <div class="grid-item-item-FBLIKE">
            {" "}
            <div style={{ padding: "10px 0px 0px 10px" }}>
              <div
                class="fb-like"
                data-href="https://www.facebook.com/TitanArmyMy"
                data-width="500"
                data-layout="standard"
                data-action="like"
                data-size="large"
                data-colorscheme="dark"
              ></div>
            </div>
          </div>
          <div class="grid-item-item-FBLIKE">
            {" "}
            <img
              src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_Madtech.png"
              height="30px"
            />
          </div>
          <div class="grid-item-item-FBLIKE">
            <div style={{ padding: "10px 0px 0px 10px" }}>
              <div
                class="fb-like"
                data-href="https://www.facebook.com/madtech.my"
                data-width="500"
                data-layout="standard"
                data-action="like"
                data-size="large"
                data-colorscheme="dark"
              ></div>
            </div>
          </div>
          <div class="grid-item-item-FBLIKE">
            <img
              src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_InWin.png"
              height="30px"
            />
          </div>
          <div class="grid-item-item-FBLIKE">
            <div style={{ padding: "10px 0px 0px 10px" }}>
              <div
                class="fb-like"
                data-href="https://www.facebook.com/inwin.my"
                data-width="500"
                data-layout="standard"
                data-action="like"
                data-size="large"
                data-colorscheme="dark"
              ></div>
            </div>
          </div>
          <div class="grid-item-item-FBLIKE">
            <img
              src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_Eclipse.png"
              height="30px"
            />
          </div>
          <div class="grid-item-item-FBLIKE">
            <div style={{ padding: "10px 0px 0px 10px" }}>
              <div
                class="fb-like"
                data-href="https://www.facebook.com/EclipseGroupMY"
                data-width="500"
                data-layout="standard"
                data-action="like"
                data-size="large"
                data-colorscheme="dark"
              ></div>
            </div>
          </div>
          <div class="grid-item-item-FBLIKE">
            {" "}
            <img
              src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_Alitrade.png"
              height="30px"
            />
          </div>
          <div class="grid-item-item-FBLIKE">
            <div style={{ padding: "10px 0px 0px 10px" }}>
              <div
                class="fb-like"
                data-href="https://www.facebook.com/AlitradeMY"
                data-width="500"
                data-layout="standard"
                data-action="like"
                data-size="large"
                data-colorscheme="dark"
              ></div>
            </div>
          </div>
        </div>
        <Grid
          container
          spacing={3}
          style={{ color: "gold", padding: "10px 10px 10px 10px " }}
        >
          <Grid container xs={12}>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}></Grid>
          </Grid>
          <Grid container xs={12}>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}></Grid>
          </Grid>
          <Grid container xs={12}>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}></Grid>
          </Grid>
          <Grid container xs={12}>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}></Grid>
          </Grid>
          <Grid container xs={12}>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}></Grid>
          </Grid>
          <Grid container xs={12}>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}></Grid>
          </Grid>
          <Grid container xs={12}>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}></Grid>
          </Grid>
          <Grid container xs={12}>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}></Grid>
          </Grid>
        </Grid>
        <div class="grid-container">
          <div class="grid-item grid-item-title">
            <img
              src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_Maxsun.png"
              width="100%"
            />
          </div>

          <div class="grid-item-item-Maxsun">
            <div
              style={{
                border: "none",
                overflow: "hidden",
                backgroundColor: "#fff",
                display: "flex",
                justifyContent: "center",
                dataColorscheme: "dark",
              }}
              class="fb-post"
              data-href="https://www.facebook.com/photo/?fbid=855968921933980&set=a.664108341120040"
              data-width="150"
            ></div>
          </div>
          <div class="grid-item-item-Maxsun">
            <div
              style={{
                border: "none",
                overflow: "hidden",
                backgroundColor: "#fff",
                display: "flex",
                justifyContent: "center",
                dataColorscheme: "dark",
              }}
              class="fb-post"
              data-href="https://www.facebook.com/photo/?fbid=855350861995786&set=a.664108341120040"
              data-width="150"
            ></div>
          </div>
          <div class="grid-item-item-Maxsun">
            <div
              style={{
                border: "none",
                overflow: "hidden",
                backgroundColor: "#fff",
                display: "flex",
                justifyContent: "center",
                dataColorscheme: "dark",
              }}
              class="fb-post"
              data-href="https://www.facebook.com/photo/?fbid=855349721995900&set=a.664108341120040"
              data-width="150"
            ></div>
          </div>
          <div class="grid-item-item-Maxsun">
            <div
              style={{
                border: "none",
                overflow: "hidden",
                backgroundColor: "#fff",
                display: "flex",
                justifyContent: "center",
                dataColorscheme: "dark",
              }}
              class="fb-post"
              data-href="https://www.facebook.com/photo/?fbid=855347458662793&set=a.664108341120040"
              data-width="150"
            ></div>
            <div></div>
          </div>
        </div>
      </div>

      <div class="grid-container">
        <div class="grid-item grid-item-title">
          <img
            src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_Sades.png"
            width="100%"
          />
        </div>
        <div class="grid-item-item-Sades">
          <div
            style={{
              border: "none",
              overflow: "hidden",
              backgroundColor: "#fff",
              display: "flex",
              justifyContent: "center",
              dataColorscheme: "dark",
            }}
            class="fb-post"
            data-href="https://www.facebook.com/sades.my/posts/2964904957092310"
            data-width="150"
          ></div>
          <div></div>
        </div>
        <div class="grid-item-item-Sades">
          <div
            style={{
              border: "none",
              overflow: "hidden",
              backgroundColor: "#fff",
              display: "flex",
              justifyContent: "center",
              dataColorscheme: "dark",
            }}
            class="fb-post"
            data-href="https://www.facebook.com/sades.my/posts/2964904127092393"
            data-width="150"
          ></div>
          <div></div>
        </div>
        <div class="grid-item-item-Sades">
          <div
            style={{
              border: "none",
              overflow: "hidden",
              backgroundColor: "#fff",
              display: "flex",
              justifyContent: "center",
              dataColorscheme: "dark",
            }}
            class="fb-post"
            data-href="https://www.facebook.com/sades.my/posts/2964902730425866"
            data-width="150"
          ></div>
          <div></div>
        </div>
        <div class="grid-item-item-Sades">
          <div
            style={{
              border: "none",
              overflow: "hidden",
              backgroundColor: "#fff",
              display: "flex",
              justifyContent: "center",
              dataColorscheme: "dark",
            }}
            class="fb-post"
            data-href="https://www.facebook.com/sades.my/posts/2964896427093163"
            data-width="150"
          ></div>
          <div></div>
        </div>
        <div class="grid-item-item-Sades">
          <div
            style={{
              border: "none",
              overflow: "hidden",
              backgroundColor: "#fff",
              display: "flex",
              justifyContent: "center",
              dataColorscheme: "dark",
            }}
            class="fb-post"
            data-href="https://www.facebook.com/sades.my/posts/2964886440427495"
            data-width="150"
          ></div>
          <div></div>
        </div>
      </div>

      <div class="grid-container">
        <div class="grid-item grid-item-title">
          <img
            src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_TitanArmy.png"
            width="100%"
          />
        </div>
        <div class="grid-item-item-TitanArmy">
          <div
            style={{
              border: "none",
              overflow: "hidden",
              backgroundColor: "#fff",
              display: "flex",
              justifyContent: "center",
              dataColorscheme: "dark",
            }}
            class="fb-post"
            data-href="https://www.facebook.com/TitanArmyMy/photos/a.530487494138566/1292138394640135/"
            data-width="150"
          ></div>
        </div>

        <div class="grid-item-item-TitanArmy">
          <div
            style={{
              border: "none",
              overflow: "hidden",
              backgroundColor: "#fff",
              display: "flex",
              justifyContent: "center",
              dataColorscheme: "dark",
            }}
            class="fb-post"
            data-href="https://www.facebook.com/TitanArmyMy/photos/a.530487494138566/1292122347975073/"
            data-width="150"
          ></div>
        </div>

        <div class="grid-item-item-TitanArmy">
          <div
            style={{
              border: "none",
              overflow: "hidden",
              backgroundColor: "#fff",
              display: "flex",
              justifyContent: "center",
              dataColorscheme: "dark",
            }}
            class="fb-post"
            data-href="https://www.facebook.com/TitanArmyMy/photos/a.530487494138566/1292121801308461/"
            data-width="150"
          ></div>
        </div>

        <div class="grid-item-item-TitanArmy">
          <div
            style={{
              border: "none",
              overflow: "hidden",
              backgroundColor: "#fff",
              display: "flex",
              justifyContent: "center",
              dataColorscheme: "dark",
            }}
            class="fb-post"
            data-href="https://www.facebook.com/TitanArmyMy/photos/a.530487494138566/1292118877975420/"
            data-width="150"
          ></div>
        </div>
        <div class="grid-item-item-TitanArmy">
          <div
            style={{
              border: "none",
              overflow: "hidden",
              backgroundColor: "#fff",
              display: "flex",
              justifyContent: "center",
              dataColorscheme: "dark",
            }}
            class="fb-post"
            data-href="https://www.facebook.com/TitanArmyMy/photos/a.530487494138566/1292116051309036/"
            data-width="150"
          ></div>
        </div>
      </div>
    </div>
  );
}
